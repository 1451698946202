<template>
  <div class="detail">
    <div class="detail-con">
      <!-- 返回按钮 -->
      <div class="detail-back-con flex align-center justify-between">
        <div class="detail-top-left flex align-center">
          <div class="detail-top-left-shu"></div>
          <div class="detail-top-left-text">水质概况详表</div>
        </div>
        <div class="flex">
          <div
            @click="back()"
            class="detail-back flex justify-center align-center"
            style="margin-right: 20px"
          >
            <img
              src="../../../assets/img/layout/back.png"
              class="detail-back-img"
            />
            <div class="detail-back-text">
              返回
            </div>
          </div>
          <div class="detail-top-right flex align-center">
            <a-button
              type="primary"
              class="detail-top-right-left"
              @click="selectAll(2)"
              v-if="identity == 0 || identity == 1"
            >
              查看全省所有的县
            </a-button>
            <a-button
              type="primary"
              class="detail-top-right-left"
              @click="selectAll(0)"
              v-if="identity == 2"
            >
              查看全市所有的县
            </a-button>
            <a-button type="primary">
              导出
            </a-button>
          </div>
        </div>
      </div>

      <!-- 表格上面内容 -->
      <div class="detail-top flex justify-between align-center">
        <div class="detail-top-left flex align-center">
          <!-- <div class="detail-top-left-shu"></div>
          <div class="detail-top-left-text">水质概况详表</div> -->
        </div>
        <!--<div class="detail-top-right flex align-center">-->
        <!--<a-button-->
        <!--type="primary"-->
        <!--class="detail-top-right-left"-->
        <!--@click="selectAll(2)"-->
        <!--v-if="identity == 0 || identity == 1"-->
        <!--&gt;-->
        <!--查看全省所有的县-->
        <!--</a-button>-->
        <!--<a-button-->
        <!--type="primary"-->
        <!--class="detail-top-right-left"-->
        <!--@click="selectAll(0)"-->
        <!--v-if="identity == 2"-->
        <!--&gt;-->
        <!--查看全市所有的县-->
        <!--</a-button>-->
        <!--<a-button type="primary">-->
        <!--导出-->
        <!--</a-button>-->
        <!--</div>-->
      </div>
      <!-- 表格 -->
      <div class="detail-table">
        <div class="detail-table-con">
          <div class="detail-table-content">
            <div class="detail-table-content-top flex align-center ">
              <div
                class="detail-table-content-itemA width100 flex align-center justify-center"
                style="width:70px"
              >
                <div class="detail-table-content-itemA-con">
                  序列号
                </div>
              </div>
              <div
                class="detail-table-content-itemA width100 flex align-center justify-center"
                style="width:140px"
              >
                <div class="detail-table-content-itemA-con">
                  省（自治区、直辖市）市或县级名称
                </div>
              </div>
              <div
                class="detail-table-content-items"
                v-for="(item, index) in columns"
                :key="index"
              >
                <div
                  v-if="!item.children"
                  class="detail-table-content-itemA width100 flex align-center justify-center relative"
                  :style="{ width: item.width + 'px' }"
                >
                  <div class="detail-table-content-itemA-con">
                    {{ item.title }}
                  </div>
                  <!-- <img
                    @click="activefn(index, false)"
                    src="../../../assets/img/layout/active.png"
                    class="table-active absolute"
                    v-if="item.active"
                  />
                  <img
                    @click="activefn(index, true)"
                    src="../../../assets/img/layout/noActive.png"
                    class="table-active absolute"
                    v-else
                  /> -->
                </div>
                <div v-else class="detail-table-content-itemB relative">
                  <div class="detail-table-content-itemB-top">
                    {{ item.title }}
                  </div>
                  <div
                    class="detail-table-content-itemB-bottom flex align-center"
                  >
                    <div
                      v-for="(i, indexi) in item.children"
                      :key="indexi"
                      class="detail-table-content-itemB-bottom-item flex align-center justify-center"
                    >
                      <div
                        v-html="i.name"
                        class="detail-table-content-itemB-bottom-item-text"
                      >
                        <!-- {{ i.name }} -->
                      </div>
                    </div>
                  </div>
                  <!-- <img
                    @click="activefn(index, false)"
                    src="../../../assets/img/layout/active.png"
                    class="table-activeB absolute"
                    v-if="item.active"
                  />
                  <img
                    @click="activefn(index, true)"
                    src="../../../assets/img/layout/noActive.png"
                    class="table-activeB absolute"
                    v-else
                  /> -->
                </div>
              </div>
              <div
                class="detail-table-content-itemA width100 flex align-center justify-center"
              >
                <div class="detail-table-content-itemA-con">
                  规模化供水率（%）
                </div>
              </div>
            </div>
            <div
              class="detail-table-content-list flex align-center"
              v-for="(item, index) in list"
              :key="index"
            >
              <div
                class="detail-table-content-list-item width100"
                style="width:70px"
              >
                {{ index + 1 }}
              </div>
              <div
                class="detail-table-content-list-item width100"
                style="width:140px"
              >
                {{ item.adName }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.sampleNum }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.goodSampleNum }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.goodPow }}
              </div>
              <div
                class="detail-table-content-list-item width100"
                style="width:120px"
              >
                {{ item.coverPeo }}
              </div>
              <div
                class="detail-table-content-list-item width100"
                style="width:120px"
              >
                {{ item.goodCoverPeo }}
              </div>
              <div
                class="detail-table-content-list-item width100"
                style="width:120px"
              >
                {{ item.goodPeoPow }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.coverPla }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.goodCoverPla }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.goodPlaPow }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.detectionNum }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.goodDetectionNum }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.goodDetectionPow }}
              </div>
              <div class="detail-table-content-list-item width100">
                {{ item.num }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const columns = [
  {
    title: "总水样数（件）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "达标水样数（件）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "水样达标率（%）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "覆盖农村供水总人口（万人）",
    dataIndex: "address",
    key: "address",
    width: 120,
    active: false
  },
  {
    title: "达标覆盖农村供水人口（万人）",
    dataIndex: "address",
    key: "address",
    width: 120,
    active: false
  },
  {
    title: "达标覆盖人口比例（%）",
    dataIndex: "address",
    key: "address",
    width: 120,
    active: false
  },
  {
    title: "检测工程处数（处）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "达标工程处数（处）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "供水工程达标率（%）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "检测指标总项次（项）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "达标指标项次（项）",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  },
  {
    title: "项次达标率（%） ",
    dataIndex: "address",
    key: "address",
    width: 100,
    active: false
  }
];
import { getConsumMultiQuality } from "@/api/water/index";
import { getDefaultArea } from "@/api/area/index";
export default {
  data() {
    return {
      columns,
      list: [
        // {
        //   name: "山西",
        //   num: 99.99
        // },
        // {
        //   name: "太原",
        //   num: 99.99
        // },
        // {
        //   name: "太原",
        //   num: 99.99
        // },
        // {
        //   name: "山西",
        //   num: 99.99
        // },
        // {
        //   name: "太原",
        //   num: 99.99
        // },
        // {
        //   name: "太原",
        //   num: 99.99
        // },
        // {
        //   name: "山西",
        //   num: 99.99
        // },
        // {
        //   name: "太原",
        //   num: 99.99
        // },
        // {
        //   name: "太原",
        //   num: 99.99
        // }
      ],
      // 筛选条件
      param: {
        areaIds: [],
        projectTypes: [],
        reportYear: undefined,
        sampleTypes: [],
        season: [],
        waterTypes: []
      },
      identity: 0,
      current: 1, //分页页码
      total: 0 //总数
    };
  },
  created() {
    if (this.$route.query.areaIds) {
      this.param.areaIds = JSON.parse(this.$route.query.areaIds);
    }
    if (this.$route.query.projectTypes) {
      this.param.projectTypes = JSON.parse(this.$route.query.projectTypes);
    }
    if (this.$route.query.reportYear) {
      this.param.reportYear = this.$route.query.reportYear;
    }
    if (this.$route.query.sampleTypes) {
      this.param.sampleTypes = JSON.parse(this.$route.query.sampleTypes);
    }
    if (this.$route.query.season) {
      this.param.season = JSON.parse(this.$route.query.season);
    }
    if (this.$route.query.waterTypes) {
      this.param.waterTypes = JSON.parse(this.$route.query.waterTypes);
    }
    this.getConsumMult({
      areaIds: this.param.areaIds,
      projectTypes: this.param.projectTypes,
      queryBy: 0,
      reportYear: this.param.reportYear,
      sampleTypes: this.param.sampleTypes,
      season: this.param.season,
      waterTypes: this.param.waterTypes,
      pageSize: 20
    });

    let data = getDefaultArea();
    this.identity = data.identity;
  },
  mounted() {},
  methods: {
    // 区域比较
    getConsumMult(data) {
      this.list = [];
      getConsumMultiQuality(data).then(res => {
        if (res.code == 200) {
          this.total = res.data.total;
          for (let i in res.data.list) {
            let result = res.data.list[i];
            this.list.push({
              no: "序列号",
              adName: result.adName,
              sampleNum: result.sampleNum,
              goodSampleNum: result.goodSampleNum,
              goodPow: result.goodPow,
              coverPeo: result.coverPeo,
              goodCoverPeo: result.goodCoverPeo,
              goodPeoPow: result.goodPeoPow,
              coverPla: result.coverPla,
              goodCoverPla: result.goodCoverPla,
              goodPlaPow: result.goodPlaPow,
              detectionNum: result.detectionNum,
              goodDetectionNum: result.goodDetectionNum,
              goodDetectionPow: result.goodDetectionPow
            });
          }
        }
      });
    },
    selectAll(queryBy) {
      this.getConsumMult({
        areaIds: this.param.areaIds,
        projectTypes: this.param.projectTypes,
        queryBy: queryBy,
        reportYear: this.param.reportYear,
        sampleTypes: this.param.sampleTypes,
        season: this.param.season,
        waterTypes: this.param.waterTypes
      });
    },
    back() {
      this.$router.go(-1);
    },
    activefn(index, active) {
      console.log(1111111);
      this.columns[index].active = active;
    }
  }
};
</script>

<style scoped lang="less">
.detail {
  width: 100%;
  height: 100vh;
  background: #f5f5f5;
  position: fixed;
  top: 0px;
  left: 0px;
  padding: 30px;
  box-sizing: border-box;
  overflow: auto;
}
.detail-con {
  width: 100%;
  height: 860px;
  padding: 30px 20px;
  box-sizing: border-box;
  background: #ffffff;
}
.detail-back-con {
  margin-bottom: 20px;
}
.detail-back {
  width: 80px;
  height: 30px;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  // margin-bottom: 21px;
  cursor: pointer;
}
.detail-back-img {
  width: 16px;
  height: 17px;
  margin-right: 8px;
}
.detail-back-text {
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
}
.detail-top {
  width: 100%;
}
.detail-top-left-shu {
  width: 4px;
  height: 16px;
  background: #1890ff;
  border-radius: 2px;
  margin-right: 8px;
}
.detail-top-left-text {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #28282c;
}
.detail-top-right-left {
  margin-right: 21px;
}
.detail-table {
  padding: 32px 0px 0px 0px;
  width: 100%;
  height: 750px;
  overflow: auto;
  margin: auto;
}
.detail-table-con {
  // margin: auto;
}
.detail-table-content {
  margin: auto;

  width: max-content;
  border-top: 1px solid #e1e1ee;
  border-left: 1px solid #e1e1ee;
}
.detail-table-content-top {
}
.table-active {
  top: -32px;
  left: 30px;
  width: 20px;
  height: 20px;
}
.table-activeB {
  top: -32px;
  left: 47%;
  width: 20px;
  height: 20px;
}
.width100 {
  width: 100px;
}
// 表头

.detail-table-content-itemA {
  height: 60px;
  background: #fafafa;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  padding: 0px 10px;
  box-sizing: border-box;
}
.detail-table-content-itemA-con {
  width: 100%;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 18px;
}
.detail-table-content-itemB {
  height: 60px;
  background: #fafafa;
  box-sizing: border-box;
}
.detail-table-content-itemB-top {
  height: 50px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #28282c;
  line-height: 50px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
}
.detail-table-content-itemB-bottom-item {
  width: 80px;
  height: 70px;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
}
.detail-table-content-itemB-bottom-item-text {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  text-align: center;
  line-height: 16px;
}
.detail-table-content-list-item {
  height: 40px;
  text-align: center;
  border-right: 1px solid #e1e1ee;
  border-bottom: 1px solid #e1e1ee;
  text-align: center;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #666666;
  line-height: 40px;
}
// 表头结束
</style>
